@font-face {
    font-family: PixelEmu;
    src: url("../fonts/PixelEmu.otf") format("opentype");
}

* {
    color: #FFFFFF;
    font-size: 19px;
    font-family: PixelEmu;
}

body {
    background: url(../images/bg.png);
    background-size: 100%;
    text-shadow: 1px 1px 2px #000;
}

.shell {
}

.contract {
}

.logo {
    margin-top: 50px;
    text-align: center;
}

.logo .header img {
    height: 175px;
}

.logo .header {
}

.logo .subheader {
    margin-top: 30px;
}

.stats {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.stats .remaining {
    margin-top: 15px;
}

.stats .timer {
}

.button {
    background-image: url('../images/button.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 300px;
    height: 90px;
    margin: 0 auto;
    cursor: pointer;
}

.connect {
    margin: 0 auto;
    text-align: center;
}

.connect .button .text {
    text-align: center;
    padding-top: 37px;
    font-size: 80%;
}

.mint .button .text {
    text-align: center;
    padding-top: 22px;
    font-size: 40px;
}

.mint #amount {
    font-size: 40px;
}

.connect {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.mint {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.mintme {
    animation: mintme 1s linear infinite;
}

@keyframes mintme {
    50% {
        opacity: 0.5;
    }
}

.faq {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.faq .faq-item {
}

.faq .faq-head {
}

.faq .faq-body {
}

.socials {
}

.socials .twitter {
}

.socials .eden {
}

.discover {
    margin-top: 50px;
    text-align: center;
}

.discover .discovery img {
}

.packs {
    text-align: center;
}

.packs img {
}

.examples img {
    height: 200px;
    margin-top: 50px;
    border-radius: 60px;
}

h1 {
    font-size: 200%;
}

.cost {
    margin-top: 10px;
}

.cost img {
    height: 20px;
}

.cost .apecost {
    display: inline;
}

.cost .apelogo {
    display: inline;
}

.slidecontainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.slider {
    -webkit-appearance: none;
    width: 500px;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: blue;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
}

.soldout {
    color: red;
}

.progress-bar__wrapper {
    position: relative;
}

.progress-bar__value {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

progress {
    width: 500px;
    height: 20px;
    border-radius: 50px;
    background-color: #eee;
    transition: width 300ms ease;
}

progress[value]::-webkit-progress-bar {
    width: 100%;
    height: 20px;
    border-radius: 50px;
    background-color: #ccc;
    transition: width 300ms ease;
}

progress[value]::-webkit-progress-value {
    width: 0;
    color: #000000 !important;
    border-radius: 50px;
    background-color: maroon;
    transition: width 300ms ease;
}

.faq {
    width: 500px;
    margin: 0 auto;
}

.faq-item {
    margin-bottom: 50px;
    width: 100%;
}

.faq-head {
    text-align: left;
    font-size: 25px;
}

.faq-body {
    font-size: 14px;
    text-align: left;
}

hr {
    width: 500px;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    border: 1px dashed #FFFFFF;
}

.socials {

}

.socials .twitter {
}

.socials .eden {
}

.contract {
    text-align: center;
    margin-top: 30px;
}
